import React, { Component, Fragment } from "react";

export default class InvalidSession extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        <div className="body column">
          <div className="column wrapper description_container desktop_error">
            <p className={"description"}>Your session is invalid or expired.</p>
            <p className={"description"}>
              Please scan the QR code from your KYC application.
            </p>
          </div>
        </div>
      </Fragment>
    );
  }
}

import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { setOnboardToken } from "./actions/configActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

function Home(props) {
  const location = useLocation();
  const { search } = location;
  const urlParams = new URLSearchParams(search);
  const token = urlParams.get("token");
  const tokenFromStorage = localStorage.getItem("@dsidscan/auth");

  /*
  React.useEffect(() => {
    if (!token) props.history.replace("/error/invalidsession");
  });
  */

  React.useEffect(() => {
    if (!token && !tokenFromStorage) {
      props.history.replace("/error/invalidsession");
    } else if (token) {
      localStorage.setItem("@dsidscan/auth", token);
      props.history.replace("/capture/photo");
    } else {
      props.history.replace("/capture/photo");
    }
  }, []);

  return null;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOnboardToken }, dispatch);
}

export default connect(null, mapDispatchToProps)(Home);

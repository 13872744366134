import React, { Component, Fragment } from "react";
import Header from "../Header";

export default class Submission extends Component {
  closeWindow() {
    window.opener && window.opener.focus();
    window.close();
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="body column results" style={{ marginTop: "30px" }}>
          <div className="wrapper">
            Thank you for completing your ID verification! Close this screen and
            continue your KYC application.
          </div>
          {window.opener && (
            <div
              className="wrapper column capture_controls"
              style={{ marginTop: "30px" }}
            >
              <label className="btn" onClick={this.closeWindow.bind(this)}>
                <p className={"buttonBgText"}>Continue KYC</p>
              </label>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

import React, {Component} from 'react';

export default class Header extends Component {
    render() {
        return (
            <header>
                <div className='row wrapper'>
                    <div className='logo'></div>
                </div>
            </header>
        );
    }
}


import request from "../shared/request";

function postID(side, file) {
  const token = localStorage.getItem("@dsidscan/auth");
  return request({
    url: `/api/bcda/v2/customers/${token}/id?side=${side}`,
    method: "POST",
    data: file,
  });
}

function postSelfie(file) {
  const token = localStorage.getItem("@dsidscan/auth");
  return request({
    url: `/api/bcda/v2/customers/${token}/selfie`,
    method: "POST",
    data: "data:image/jpeg;base64," + file,
  });
}

const Apis = {
  postID,
  postSelfie,
};

export default Apis;
